<template>
  <div>
    <b-modal
      ref="modal-clock-time"
      size="lg"
      centered
      style="width: 80vw"
      hide-footer
      title="Add time"
    >
      <b-container>
        <b-overlay
          :show="
            isLoading ||
              sickDetails.isLoading ||
              vacationDetails.isLoading ||
              rangeDetails.isLoading
          "
          :opacity="0.5"
          spinner-variant="secondary"
          rounded="sm"
        >
          <b-row class="mb-2">
            <b-col cols="4" lg="2" class="font-weight-bold"> Now: </b-col>
            <b-col cols="8" lg="4"> {{ date }} {{ time }} </b-col>
            <b-col cols="4" lg="2" class="font-weight-bold"> Name: </b-col>
            <b-col cols="8" lg="4">
              {{ this.userData.full_name }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" lg="2" class="font-weight-bold mb-2">
              Day type:
            </b-col>
            <b-col cols="8" lg="4" class="mb-2">
              <inline-select
                id="day-type"
                :value="data.daytype"
                label="Day type"
                :hide-label="true"
                :readonly="false"
                :options="controls.daytype.options"
                :allow-empty="false"
                :mode="$constants.FORMCONTROLMODE.EDIT"
                :required="true"
                @changed="onDayTypeChange"
              />
            </b-col>
            <b-col cols="4" lg="2" class="font-weight-bold mb-2">
              Daily duration:
            </b-col>
            <b-col cols="8" lg="4" class="mb-2">
              <inline-select
                id="duration"
                :value="data.duration"
                label="Duration"
                :hide-label="true"
                :readonly="false"
                :options="controls.duration.options"
                :allow-empty="false"
                :mode="$constants.FORMCONTROLMODE.EDIT"
                :required="true"
                @changed="onDurationChange"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" lg="3" class="font-weight-bold mb-2">
              Ignore weekends
            </b-col>
            <b-col cols="2" lg="3" class="mb-2">
              <inline-switch
                id="sw-ignore-weekends"
                :value="data.ignoreWeekends"
                :hide-label="true"
                @changed="onIgnoreWeekendsChange"
              />
            </b-col>
            <b-col cols="4" lg="3" class="font-weight-bold mb-2">
              Request unpaid time
            </b-col>
            <b-col cols="2" lg="3" class="mb-2">
              <inline-switch
                id="sw-unpaid-time"
                :value="data.unpaidTime"
                :hide-label="true"
                @changed="onUnpaidTimeChange"
              />
            </b-col>
          </b-row>

          <span v-if="isSingleDay">
            <b-row>
              <b-col cols="4" lg="2" class="font-weight-bold mb-2">Date:</b-col>
              <b-col cols="8" lg="4" class="mb-2">
                <date-range-picker-custom
                  id="date-picker"
                  default-range="Today"
                  v-model="data.date1"
                  :single-date-picker="true"
                  :ranges="{}"
                />
              </b-col>
            </b-row>
          </span>
          <span v-if="!isSingleDay">
            <b-row>
              <b-col cols="4" lg="2" class="font-weight-bold mb-2"
                >From date:</b-col
              >
              <b-col cols="8" lg="4" class="mb-2">
                <date-range-picker-custom
                  id="date-picker"
                  default-range="Today"
                  v-model="data.date1"
                  :single-date-picker="true"
                  :ranges="{}"
                />
              </b-col>
              <b-col cols="4" lg="2" class="font-weight-bold mb-2"
                >To date:</b-col
              >
              <b-col cols="8" lg="4" class="mb-2">
                <date-range-picker-custom
                  id="date-picker"
                  default-range="Today"
                  v-model="data.date2"
                  :single-date-picker="true"
                  :ranges="{}"
                />
              </b-col>
            </b-row>
          </span>
          <b-row
            v-if="
              data.daytype.id !== $constants.DAY_TYPE.VACATION &&
                data.daytype.id !== $constants.DAY_TYPE.PERSONAL_LEAVE
            "
          >
            <b-col cols="4" lg="2" class="font-weight-bold mb-2">
              From time:
            </b-col>
            <b-col cols="8" lg="4" class="mb-2">
              <vue-timepicker
                :minute-interval="5"
                format="HH:mm"
                v-model="data.time1"
                lazy
              />
            </b-col>
            <b-col cols="4" lg="2" class="font-weight-bold mb-2">
              To time:
            </b-col>
            <b-col cols="8" lg="4" class="mb-2">
              <vue-timepicker
                :minute-interval="5"
                format="HH:mm"
                v-model="data.time2"
                lazy
              />
            </b-col>
          </b-row>
          <!--
          <span
            v-if="
              (data.daytype.id !== $constants.DAY_TYPE.HOLIDAY &&
                data.availableDates) ||
                data.notAvailableDates
            "
          >
-->
          <span>
            <b-row>
              <b-col cols="12" lg="12" class="font-weight-bold mb-2"
                >Available dates:</b-col
              >
              <b-col cols="12" lg="12" class="mb-2"
                >{{ data.availableDates }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="12" class="font-weight-bold mb-2"
                >Not available dates:</b-col
              >
              <b-col cols="12" lg="12" class="mb-2">
                {{ data.notAvailableDates }}
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" lg="12" class="font-weight-bold mb-2">
                Range details (days):
                <b-spinner v-if="rangeDetails.isLoading" small type="grow" />
              </b-col>
              <b-col cols="12" lg="12" class="mb-2">
                <b-table :items="rangeDetails.data" responsive />
              </b-col>
            </b-row>
          </span>

          <b-row
            v-if="
              data.daytype.id === $constants.DAY_TYPE.VACATION ||
                data.daytype.id === $constants.DAY_TYPE.PERSONAL_LEAVE
            "
          >
            <b-col cols="12" lg="12" class="font-weight-bold mb-2">
              Vacation Details (hours):
              <b-spinner v-if="vacationDetails.isLoading" small type="grow" />
            </b-col>
            <b-col cols="12" lg="12" class="mb-2">
              <b-table :items="vacationDetails.data" responsive />
            </b-col>
          </b-row>
          <b-row v-if="data.daytype.id === $constants.DAY_TYPE.SICK">
            <b-col cols="12" lg="12" class="font-weight-bold mb-2">
              Sick Details (hours):
              <b-spinner v-if="sickDetails.isLoading" small type="grow" />
            </b-col>
            <b-col cols="12" lg="12" class="mb-2">
              <b-table :items="sickDetails.data" responsive />
            </b-col>
          </b-row>
          <b-row v-if="location === 'offsite'">
            <b-col cols="12" lg="12" class="font-weight-bold mb-2">
              Notes:
            </b-col>
            <b-col cols="12" lg="12" class="mb-2">
              <inline-input
                :value="data.notes"
                :rows="2"
                @changed="onNotesChanged"
                label="Notes"
                :hide-label="true"
                :readonly="false"
                :mode="$constants.FORMCONTROLMODE.EDIT"
                :required="controls.notes.required"
                :is-text-area="true"
              />
            </b-col>
          </b-row>
          <b-row v-if="location === 'offsite'">
            <b-col cols="12" lg="12">
              Rules:
              <pre>{{ ruleNotesSelected }}</pre>
            </b-col>
          </b-row>
          <b-row v-if="location === 'offsite'">
            <b-col cols="12" lg="12">
              Checks:

              <pre v-html="conclusionText"></pre>
            </b-col>
          </b-row>
        </b-overlay>
      </b-container>

      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button
          v-if="controls.saveButton.visible"
          :variant="controls.saveButton.color"
          class="m-1"
          :disabled="controls.saveButton.disabled || isLoading"
          @click="save()"
        >
          <font-awesome-icon
            v-if="!isLoading"
            :icon="controls.saveButton.icon"
          />
          <b-spinner v-if="isLoading" small type="grow" />
          {{ controls.saveButton.title }}
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="close()">
          <font-awesome-icon icon="ban" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'

import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'
import InlineSwitch from '@/components/InlineSwitch'

import DateRangePickerCustom from '@/components/DateRangePickerCustom'

import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

import constants from '@/shared/constants.js'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'AddTimeDialog',
  components: {
    DateRangePickerCustom,
    VueTimepicker,

    InlineSwitch,
    InlineInput,
    InlineSelect
  },
  props: [],
  data: () => {
    return {
      isLoading: false,
      isSingleDay: undefined,
      nowUTC: '',
      nowClient: '',
      track_id: '',
      isTimeTracking: false,
      action: undefined,
      ruleNotesSelected: '',
      rules: {
        VL: {
          text: `
1. Days are accrued on January 1, March 1, May 1, July 1, and September 1 of every year.
2. Each employee is only entitled to use 40 hours of VL in any 12-month period between January 1st and December 31st
3. No more than one week of PLA and VL combined may be taken at one time
4. While a foreseeable request to use PLA with VL can be made with seven (7) calendar days’ notice
5. It is advisable to request combined or stacked PLA and VL at least 90 days prior to the beginning of the requested vacation period
6. Employees may not carry over VL.`,
          checks: {
            availableHours: '',
            combinedVLPLA: '',
            sevenDaysNotice: '',
            ninetyDaysNotice: ''
          }
        },
        PSL: {
          text: `
1. Paid Sick Leave accrues at a rate of 1 hour per 35 hours.
2. All regular full-time and part-time employees are eligible for PSL and PLA provided they worked 80 hours for KOVAL within any 120-day period after a 30-day introductory period.
3. PSL accrues from the date of employment. Accruals are pro-rated for part-time employees based on their regular work hours
4. Is only entitled to use 40 hours of PSL in any 12-month period between January 1st and December 31st.
5. Employees may carry over up to 80 hours of unused accrued PSL, to the next 12-month accrual period from January 1st through December 31st. The carried over PSL can only be used as PSL. Regardless of how much time is carried over into the next calendar year, there is a limit of 40 hours of PSL that may be used in any calendar year
`,
          checks: {
            availableHours: '',
            period150days: ''
          }
        },
        PLA: {
          text: `
1. Accrues at a rate of 1 hour per 35 hours.
2. Employee is only entitled to use 40 hours of PLA in any 12-month period between January 1st and December 31st.
3. Employees may carry over up to 16 hours of unused accrued PLA, to the next 12-month accrual period from January 1st through December 31st. Regardless of how much time is carried over into the next calendar year, there is a limit of 40 hours of PLA that may be used in any calendar year.`,
          checks: {
            availableHours: '',
            combinedVLPLA: ''
          }
        }
      },
      conclusionText: '',
      controls: {
        daytype: {
          options: [
            {
              id: constants.DAY_TYPE.SICK,
              label: 'Personal Sick Leave [PSL]'
            },
            {
              id: constants.DAY_TYPE.VACATION,
              label: 'Vacation Leave [VL]'
            },
            {
              id: constants.DAY_TYPE.PERSONAL_LEAVE,
              label: 'Personal leave [PLA]'
            },
            {
              id: constants.DAY_TYPE.FORGOTTEN,
              label: 'Forgotten day'
            }
          ]
        },
        duration: {
          options: [
            {
              id: 10,
              label: '10 hours'
            },
            {
              id: 8,
              label: '8 hours'
            },
            {
              id: 4,
              label: '4 hours'
            },
            {
              id: 2,
              label: '2 hours'
            }
          ]
        },
        notes: {
          required: false
        },
        saveButton: {
          title: 'Save',
          color: 'success',
          icon: 'save',
          disabled: false,
          visible: true
        }
      },
      userData: {
        id: 0,
        full_name: ''
      },
      data: {
        daytype: '',
        duration: {
          id: 8,
          label: '8 hours'
        },
        date1: '',
        date2: '',
        time1: '',
        time2: '',
        notes: '',
        changed: false,
        ignoreWeekends: false,
        unpaidTime: false
      },
      sickDetails: {
        data: [
          {
            Worked: 0,
            Rollover: 0,
            Accrued: 0,
            Used: 0,
            NotUsed: 0,
            Available: 0,
            Request: 0,
            Paid: 0,
            Unpaid: 0,
            Total: 0
          }
        ],
        isLoading: false
      },
      vacationDetails: {
        data: [
          {
            Worked: 0,
            Rollover: 0,
            Accrued: 0,
            Used: 0,
            NotUsed: 0,
            Available: 0,
            Request: 0,
            Paid: 0,
            Unpaid: 0,
            Total: 0
          }
        ],
        isLoading: false
      },
      rangeDetails: {
        data: [
          {
            Work: 0,
            Holidays: 0,
            Vacation: 0,
            Sick: 0,
            Weekends: 0,
            Available: 0
          }
        ],
        isLoading: false
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile,
      timeTracker: state => state.timeTracker
    }),
    location () {
      let ip = window.location.host

      if (ip !== process.env.OFFICE_IP) return 'offsite'
      else return '4241'
    },
    date () {
      return moment(this.nowClient).format('YYYY-MM-DD')
    },
    time () {
      return moment(this.nowClient).format('HH:mm:ss')
    },
    minDate () {
      return moment()
        .subtract(14, 'day')
        .format('YYYY-MM-DD')
    }
  },
  created () {
    //let timerID = setInterval(this.updateTime, 1000);
    this.ruleNotesSelected = ''
    setInterval(this.updateTime, 1000)
    this.updateTime()

    this.data.time1 = '09:00'
    this.data.time2 = '17:00'

    //show vacation option for admin
    if (this.profile.data.role === 'H2') {
      this.controls.daytype.options.push({
        id: this.$constants.DAY_TYPE.HOLIDAY,
        label: 'Holiday'
      })
    }

    //20201127
    this.userData = {
      id: this.profile.data.id,
      full_name: this.profile.data.full_name
    }
  },
  methods: {
    ...mapActions('timeTracker', [
      'clockIn',
      'clockOut',
      'lunch',
      'addTime',
      'addHoliday',
      'isHoliday'
    ]),
    updateTime () {
      this.nowUTC = moment().format('YYYY-MM-DD HH:mm:ss')
      this.nowClient = moment
        .tz(moment(), moment.tz.guess())
        .format('YYYY-MM-DD HH:mm:ss')
    },
    onDateChange () {
      if (this.isSingleDay) {
        this.data.date2 = this.data.date1
      }

      console.log('onDateChange', this.data.date1, this.data.date2)
      if (this.data.date2 < this.data.date1) {
        this.data.date2 = this.data.date1
      }

      this.$forceUpdate()

      this.checkAndUpdate()
    },
    onDayTypeChange: function (id, val) {
      console.log('onDayTypeChange', val)

      this.data.daytype = val

      this.ruleNotesSelected = ''

      if (val.id === this.$constants.DAY_TYPE.FORGOTTEN) {
        console.log('onDayTypeChange.FORGOTTEN')

        this.controls.notes.required = true

        this.controls.saveButton.visible = true
      } else {
        this.controls.notes.required = false
      }

      if (val.id === this.$constants.DAY_TYPE.HOLIDAY) {
        console.log('onDayTypeChange.HOLIDAY')

        this.controls.saveButton.visible = true
      }

      if (val.id === this.$constants.DAY_TYPE.VACATION) {
        console.log('onDayTypeChange.VACATION')

        this.ruleNotesSelected = this.rules.VL.text
        this.controls.saveButton.visible = false
      }
      if (val.id === this.$constants.DAY_TYPE.SICK) {
        console.log('onDayTypeChange.SICK')
        this.ruleNotesSelected = this.rules.PSL.text

        this.controls.saveButton.visible = false
      }
      if (val.id === this.$constants.DAY_TYPE.PERSONAL_LEAVE) {
        console.log('onDayTypeChange.PERSONAL_LEAVE')

        this.ruleNotesSelected = this.rules.PLA.text
        this.controls.saveButton.visible = false
      }
      this.checkAndUpdate()
    },
    onIgnoreWeekendsChange (id, value) {
      console.log('onIgnoreWeekendsChange', value)
      this.data.ignoreWeekends = value
      this.checkAndUpdate()
    },
    onUnpaidTimeChange (id, value) {
      //this.data.updateTime = value;
      this.data.unpaidTime = value
      this.updateVacationTotals()
      this.updateSickTotals()
    },
    async checkAndUpdate () {
      console.log('checkAndUpdate')

      await this.checkDateRange()
      console.log('checkAndUpdate1.checkDateRange', this.rangeDetails.data)
      try {
        if (this.data.daytype.id === this.$constants.DAY_TYPE.VACATION) {
          this.vacationDetails.isLoading = true
          let userId = this.userData.id
          //let userId = 21
          //let userId = 147

          let v = await this.$api.get(
            `timetracker/users/${userId}/vacation/statistics-new`
          )

          console.log('vacationDetails:', v)

          this.vacationDetails.isLoading = false

          let currentData = v.find(i => i.yy == moment().format('YYYY'))

          this.vacationDetails.data = [
            {
              Rollover: currentData.prev_rollover_h,
              Worked: currentData.worked_h,
              Accrued: currentData.accrued_h,
              Used: currentData.used_h,
              NotUsed: currentData.not_used_h,
              Available: currentData.available_h,
              Request: 0,
              Paid: 0,
              Unpaid: 0,
              Total: 0
            }
          ]

          this.updateVacationTotals()

          await this.checkRulesVL(currentData)

          console.log('checkAndUpdate.VACATION')
          this.controls.saveButton.visible =
            this.vacationDetails.data[0].Total > 0
        }

        if (this.data.daytype.id === this.$constants.DAY_TYPE.PERSONAL_LEAVE) {
          this.vacationDetails.isLoading = true

          let userId = this.userData.id
          //let userId = 21
          //let userId = 147

          let v = await this.$api.get(
            `timetracker/users/${userId}/pla/statistics-new`
          )

          console.log('plaDetails:', v)

          this.vacationDetails.isLoading = false

          let currentData = v.find(i => i.yy == moment().format('YYYY'))

          this.vacationDetails.data = [
            {
              Rollover: currentData.prev_rollover_h,
              Worked: currentData.worked_h,
              Accrued: currentData.accrued_h,
              Used: currentData.used_h,
              NotUsed: currentData.not_used_h,
              Available: currentData.available_h,

              Request: 0,
              Paid: 0,
              Unpaid: 0,
              Total: 0
            }
          ]

          this.updateVacationTotals()

          await this.checkRulesPLA(currentData)
          console.log('checkAndUpdate.PERSONAL_LEAVE')
          this.controls.saveButton.visible =
            this.vacationDetails.data[0].Total > 0
        }

        if (this.data.daytype.id === this.$constants.DAY_TYPE.SICK) {
          this.sickDetails.isLoading = true

          let v = await this.$api.get(
            `timetracker/users/${this.userData.id}/sick/statistics-new`
          )

          console.log('sickDetails:', v)

          this.sickDetails.isLoading = false

          let currentData = v.find(i => i.yy == moment().format('YYYY'))

          this.sickDetails.data = [
            {
              Rollover: currentData.prev_rollover_h,
              Worked: currentData.worked_h,
              Accrued: currentData.accrued_h,
              Used: currentData.used_h,
              NotUsed: currentData.not_used_h,
              Available: currentData.available_h,
              Request: 0,
              Paid: 0,
              Unpaid: 0,
              Total: 0
            }
          ]

          this.updateSickTotals()

          await this.checkRulesPSL(currentData)
          console.log('checkAndUpdate.SICK')
          this.controls.saveButton.visible = this.sickDetails.data[0].Total > 0
        }
      } catch (e) {
        this.$form.makeToastError(e)
      }
    },

    async checkRulesPSL (currentData) {
      let passed = `<span style="color: green">PASSED</span>`
      let failed = `<span style="color: red">FAILED</span>`
      if (currentData.available_h > 0)
        this.rules.PSL.checks.availableHours = true
      else this.rules.PSL.checks.availableHours = false

      let result = await this.$api.post(
        `timetracker/users/${this.userData.id}/sick/worked-days`,
        {
          userId: this.userData.id,
          type: 'PSL'
        }
      )

      console.log('worked-days:', JSON.stringify(result))

      if (result.days_worked > 150 && result.hours_worked > 80)
        this.rules.PSL.checks.period150days = true
      else this.rules.PSL.checks.period150days = false

      this.conclusionText = `
1. Available hours: ${this.rules.PSL.checks.availableHours ? passed : failed}
2. 80 worked hours within 30+120 days: ${
        this.rules.PSL.checks.period150days ? passed : failed
      }
Result: ${
        this.rules.PSL.checks.availableHours &&
        this.rules.PSL.checks.period150days
          ? `<span style="color: green"> You have used ${Math.floor(
              currentData.used_h
            )} hours and can use ${Math.floor(
              currentData.available_h
            )} hours of PSL time this year.</span>`
          : '<span style="color: red">You can not use PSL time. Please check the rules.</span>'
      }
      `
    },
    async checkRulesVL (currentData) {
      let passed = `<span style="color: green">PASSED</span>`
      let failed = `<span style="color: red">FAILED</span>`
      console.log('checkRulesVL', currentData)

      console.log('this.data.date1', this.data.date1)
      console.log('this.data.date2', this.data.date2)

      if (currentData.available_h > 0)
        this.rules.VL.checks.availableHours = true
      else this.rules.VL.checks.availableHours = false

      let combinedVLPLA = await this.$api.post(
        `timetracker/users/${this.userData.id}/vacation/check-combined`,
        {
          userId: this.userData.id,
          startDate: this.data.date1,
          endDate: this.data.date2,
          type: 'VL'
        }
      )

      if (combinedVLPLA == 0) this.rules.VL.checks.combinedVLPLA = true
      else this.rules.VL.checks.combinedVLPLA = false

      console.log('combinedVLPA', combinedVLPLA)

      let daysBeforeVacation = moment(this.data.date1).diff(moment(), 'days')

      if (daysBeforeVacation > 7) this.rules.VL.checks.sevenDaysNotice = true
      else this.rules.VL.checks.sevenDaysNotice = false

      if (daysBeforeVacation > 90) this.rules.VL.checks.ninetyDaysNotice = true
      else this.rules.VL.checks.ninetyDaysNotice = false

      console.log('daysBeforeVacation', daysBeforeVacation)

      this.conclusionText = `
1. Available hours: ${this.rules.VL.checks.availableHours ? passed : failed}
2. Combined VL and PLA: ${this.rules.VL.checks.combinedVLPLA ? passed : failed}
3. Seven days notice: ${this.rules.VL.checks.sevenDaysNotice ? passed : failed}
4. Ninety days notice: ${
        this.rules.VL.checks.ninetyDaysNotice ? passed : failed
      }

Result: ${
        this.rules.VL.checks.availableHours &&
        this.rules.VL.checks.combinedVLPLA &&
        this.rules.VL.checks.sevenDaysNotice
          ? `<span style="color: green">You have used ${Math.floor(
              currentData.used_h
            )} hours and can use ${Math.floor(
              currentData.available_h
            )} hours of vacation time this year.</span>`
          : '<span style="color: red">You can not use vacation time. Please check the rules.</span>'
      }
      `
      //           this.conclusionText = "You have already used 40 hours of vacation time this year. You can't use more."
      //           else
      //           this.conclusionText = `You have used ${Math.floor(currentData.used_h)} hours and have ${currentData.available_h} hours of vacation time left this year.`
    },
    async checkRulesPLA (currentData) {
      let passed = `<span style="color: green">PASSED</span>`
      let failed = `<span style="color: red">FAILED</span>`
      console.log('checkRulesPLA', currentData)

      console.log('this.data.date1', this.data.date1)
      console.log('this.data.date2', this.data.date2)

      if (currentData.available_h > 0)
        this.rules.PLA.checks.availableHours = true
      else this.rules.PLA.checks.availableHours = false

      let combinedVLPLA = await this.$api.post(
        `timetracker/users/${this.userData.id}/vacation/check-combined`,
        {
          userId: this.userData.id,
          startDate: this.data.date1,
          endDate: this.data.date2,
          type: 'PLA'
        }
      )

      if (combinedVLPLA == 0) this.rules.PLA.checks.combinedVLPLA = true
      else this.rules.PLA.checks.combinedVLPLA = false

      console.log('combinedVLPA', combinedVLPLA)

      //let daysBeforeVacation = moment(this.data.date1).diff(moment(), 'days')

      this.conclusionText = `
1. Available hours: ${this.rules.PLA.checks.availableHours ? passed : failed}
2. Combined VL and PLA: ${this.rules.PLA.checks.combinedVLPLA ? passed : failed}

Result: ${
        this.rules.PLA.checks.availableHours &&
        this.rules.PLA.checks.combinedVLPLA
          ? `<span style="color: green">You have used ${Math.floor(
              currentData.used_h
            )} hours and can use ${Math.floor(
              currentData.available_h
            )} hours of personal leave time this year.</span">`
          : '<span style="color: red">You can not use personal time. Please check the rules.</span>'
      }
      `
    },
    async checkDateRange () {
      console.log('checkDateRange1')

      let self = this

      return new Promise(function (fulfill, reject) {
        console.log('checkDateRange2')
        if (self.data.date1 && !self.data.date2)
          self.data.date2 = self.data.date1

        console.log('checkDateRange3')

        if (!self.data.date1 || !self.data.date2) {
          //self.$form.makeToastError('Please select valid interval')
          return reject('Please select valid interval')
        }
        console.log('checkDateRange4')
        self.data.availableDates = ''
        self.data.notAvailableDates = ''
        self.vacationDetails.data = [
          {
            Rollover: 0,
            Accrued: 0,
            Used: 0,
            NotUsed: 0,
            Available: 0,
            Request: 0,
            Paid: 0,
            Unpaid: 0,
            Total: 0
          }
        ]
        self.sickDetails.data = [
          {
            Accrued: 0,
            Used: 0,
            NotUsed: 0,
            Available: 0,
            Request: 0,
            Paid: 0,
            Unpaid: 0,
            Total: 0
          }
        ]

        let data = {
          startDate: self.data.date1,
          endDate: self.data.date2
        }

        self.rangeDetails.isLoading = true
        console.log('checkDateRange5')
        self.$api.post('timetracker/check-range', data).then(v => {
          console.log('checkDateRange5.v:', v)
          self.rangeDetails.isLoading = false
          console.log(
            'checkDateRange6.self.data.ignoreWeekends',
            self.data.ignoreWeekends
          )
          if (self.data.ignoreWeekends) {
            for (let d of v) {
              if (d.dayType === 'weekend') d.dayType = ''
            }
          }

          self.rangeDetails.data = [
            {
              Work: v.filter(d => d.dayType === 'regular').length,
              Holidays: v.filter(d => d.dayType === 'holiday').length,
              Vacation: v.filter(d => d.dayType === 'vacation').length,
              Sick: v.filter(d => d.dayType === 'sick').length,
              Weekends: v.filter(d => d.dayType === 'weekend').length,
              Available: v.filter(d => d.dayType === '').length
            }
          ]
          console.log(
            'checkDateRange6.self.rangeDetails.data',
            JSON.stringify(self.rangeDetails.data)
          )

          self.data.availableDates = v
            .filter(d => d.dayType === '')
            .map(e => e.trackdate)
            .join(', ')
          self.data.notAvailableDates = v
            .filter(d => d.dayType !== '')
            .map(e => `${e.trackdate} (${e.dayType})`)
            .join(', ')
          console.log(
            'checkDateRange7.self.data.availableDates ',
            self.data.availableDates
          )
          return fulfill(v)
        })
      })
    },

    onDurationChange (id, val) {
      this.data.duration = val
      if (val.id === 2) {
        this.data.time1 = '09:00'
        this.data.time2 = '11:00'
      }
      if (val.id === 4) {
        this.data.time1 = '09:00'
        this.data.time2 = '13:00'
      }
      if (val.id === 8) {
        this.data.time1 = '09:00'
        this.data.time2 = '17:00'
      }
      if (val.id === 10) {
        this.data.time1 = '09:00'
        this.data.time2 = '19:00'
      }
      this.updateVacationTotals()
      this.updateSickTotals()
    },
    updateVacationTotals () {
      //test

      console.log('updateVacationTotals1')
      if (
        this.data.daytype.id == this.$constants.DAY_TYPE.VACATION ||
        this.data.daytype.id == this.$constants.DAY_TYPE.PERSONAL_LEAVE
      ) {
        console.log('updateVacationTotals2')
        let requestDuration =
          this.rangeDetails.data[0].Available * this.data.duration.id

        this.vacationDetails.data[0].Request = requestDuration

        console.log(
          'updateVacationTotals: available:durationId:requestDuration:',
          this.rangeDetails.data[0].Available,
          this.data.duration.id,
          requestDuration
        )
        let balance =
          parseFloat(this.vacationDetails.data[0].Available) -
          parseFloat(requestDuration)

        if (balance >= 0) {
          if (this.data.unpaidTime) {
            this.vacationDetails.data[0].Paid = 0
            this.vacationDetails.data[0].Unpaid = this.vacationDetails.data[0].Request.toFixed(
              2
            )
            this.vacationDetails.data[0].Total = this.vacationDetails.data[0].Request.toFixed(
              2
            )
          } else {
            this.vacationDetails.data[0].Paid = this.vacationDetails.data[0].Request.toFixed(
              2
            )
            this.vacationDetails.data[0].Unpaid = 0
            this.vacationDetails.data[0].Total = this.vacationDetails.data[0].Request.toFixed(
              2
            )
          }
        }

        if (balance < 0) {
          if (this.data.unpaidTime) {
            this.vacationDetails.data[0].Paid = this.vacationDetails.data[0].Available
            this.vacationDetails.data[0].Unpaid = (
              parseFloat(requestDuration) -
              parseFloat(this.vacationDetails.data[0].Available)
            ).toFixed(2)
            this.vacationDetails.data[0].Total = requestDuration
          } else {
            this.vacationDetails.data[0].Paid = this.vacationDetails.data[0].Available
            this.vacationDetails.data[0].Unpaid = 0
            this.vacationDetails.data[0].Total = balance.toFixed(2)
          }
        }
      }

      console.log('updateVacationTotals:', this.vacationDetails.data[0].Total)
    },
    updateSickTotals () {
      if (this.data.daytype.id === this.$constants.DAY_TYPE.SICK) {
        let requestDuration =
          this.rangeDetails.data[0].Available * this.data.duration.id

        this.sickDetails.data[0].Request = requestDuration

        console.log(
          'updateSickTotals: available:durationId:requestDuration:',
          this.rangeDetails.data[0].Available,
          this.data.duration.id,
          requestDuration
        )

        let balance =
          parseFloat(this.sickDetails.data[0].Available) -
          parseFloat(requestDuration)

        if (balance >= 0) {
          if (this.data.unpaidTime) {
            this.sickDetails.data[0].Paid = 0
            this.sickDetails.data[0].Unpaid = this.sickDetails.data[0].Request.toFixed(
              2
            )
            this.sickDetails.data[0].Total = this.sickDetails.data[0].Request.toFixed(
              2
            )
          } else {
            this.sickDetails.data[0].Paid = this.sickDetails.data[0].Request.toFixed(
              2
            )
            this.sickDetails.data[0].Unpaid = 0
            this.sickDetails.data[0].Total = this.sickDetails.data[0].Request.toFixed(
              2
            )
          }
        }

        if (balance < 0) {
          if (this.data.unpaidTime) {
            this.sickDetails.data[0].Paid = this.sickDetails.data[0].Available
            this.sickDetails.data[0].Unpaid = (
              parseFloat(requestDuration) -
              parseFloat(this.sickDetails.data[0].Available)
            ).toFixed(2)
            this.sickDetails.data[0].Total = requestDuration
          } else {
            this.sickDetails.data[0].Paid = this.sickDetails.data[0].Available
            this.sickDetails.data[0].Unpaid = 0
            this.sickDetails.data[0].Total = balance.toFixed(2)
          }
        }
      }
    },
    onNotesChanged (field, value) {
      this.data.notes = value
    },

    /*
        _action:
        _cell:
        _user: call from payroll for different users

        */
    show (_action, _cell, _user = undefined) {
      if (_user !== undefined) this.userData = _user

      this.isLoading = false

      this.isSingleDay = false

      this.data.daytype = ''

      if (_cell) {
        this.isSingleDay = true

        this.data.date1 = moment(_cell.day).format('YYYY-MM-DD')
        this.data.date2 = this.data.date1
      }

      this.action = _action

      this.$refs['modal-clock-time'].show()
    },
    validateForm () {
      if (this.data.daytype.id === this.$constants.DAY_TYPE.HOLIDAY) return true

      if (!this.data.availableDates) {
        this.$form.makeToastError(
          'There are no available dates. Please select another interval'
        )
        return false
      }

      if (
        this.data.daytype.id === this.$constants.DAY_TYPE.VACATION &&
        this.vacationDetails.data.length > 0
      ) {
        let total = parseFloat(this.vacationDetails.data[0].Total)
        console.log('payload.hours_unpaid', this.vacationDetails.data)
        if (total < 0) {
          this.$form.makeToastError("Total can't be negative")
          return false
        }

        //???
        if (parseFloat(this.vacationDetails.data[0].Request) <= 0) {
          this.$form.makeToastError(
            `Requested vacation duration must be between 0.5 and ${total} hours`
          )
          return false
        }
      }

      if (
        this.data.daytype.id === this.$constants.DAY_TYPE.SICK &&
        this.sickDetails.data.length > 0
      ) {
        let total = parseFloat(this.sickDetails.data[0].Total)

        if (total < 0) {
          this.$form.makeToastError("Total can't be negative")
          return false
        }

        if (parseFloat(this.sickDetails.data[0].Request) <= 0) {
          this.$form.makeToastError(
            `Requested sick duration must be between 0.5 and ${total} hours`
          )
          return false
        }
      }

      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return false
      }

      //this.controls.saveButton.disabled = false

      return true
    },
    async save () {
      if (!this.validateForm()) return

      this.isLoading = true

      if (this.data.daytype.id !== this.$constants.DAY_TYPE.HOLIDAY) {
        this.saveTime()
      }

      if (this.data.daytype.id === this.$constants.DAY_TYPE.HOLIDAY) {
        this.saveHoliday()
      }
    },
    saveTime () {
      let self = this

      let payload = {
        track_id: this.track_id,
        user_id: this.userData.id,
        daytype: this.data.daytype,
        date1: this.data.date1,
        date2: this.data.date2,
        time1: this.data.time1,
        time2: this.data.time2,
        location: this.location,
        notes: this.data.notes,
        dates: this.data.availableDates
      }

      if (
        this.data.daytype.id === this.$constants.DAY_TYPE.VACATION ||
        this.data.daytype.id === this.$constants.DAY_TYPE.PERSONAL_LEAVE
      ) {
        payload.hours_available = this.vacationDetails.data[0].Available
        payload.hours_requested = this.vacationDetails.data[0].Request
        payload.hours_paid = this.vacationDetails.data[0].Paid
        payload.hours_unpaid = this.vacationDetails.data[0].Unpaid
      }
      if (this.data.daytype.id === this.$constants.DAY_TYPE.SICK) {
        payload.hours_available = this.sickDetails.data[0].Available
        payload.hours_requested = this.sickDetails.data[0].Request
        payload.hours_paid = this.sickDetails.data[0].Paid
        payload.hours_unpaid = this.sickDetails.data[0].Unpaid
      }

      payload.action = this.action

      console.log('saveTime.payload', payload)

      this.addTime(payload)
        .then(response => {
          self.isLoading = false

          self.$emit('save', payload)

          self.$form.makeToastInfo(response.message)

          self.$refs['modal-clock-time'].hide()
        })
        .catch(error => {
          self.isLoading = false

          self.$form.makeToastError(error.message)
        })
    },
    saveHoliday () {
      let self = this

      let payload = {
        user_id: this.userData.id,
        daytype: this.data.daytype,
        duration: this.data.duration,
        location: this.location,
        date1: this.data.date1,
        date2: this.data.date2,
        time1: this.data.time1,
        time2: this.data.time2,
        notes: this.data.notes
      }

      payload.action = this.action

      this.addHoliday(payload)
        .then(response => {
          self.isLoading = false

          self.$emit('save')

          self.$form.makeToastInfo(response.message)

          self.$refs['modal-clock-time'].hide()
        })
        .catch(error => {
          self.isLoading = false

          self.$form.makeToastError(error.message)
        })
    },
    close: function () {
      this.$refs['modal-clock-time'].hide()
    }
  },
  watch: {
    'data.date1': function (newVal) {
      this.onDateChange(newVal)
    },
    'data.date2': function (newVal) {
      if (!this.isSingleDay) {
        this.onDateChange(newVal)
      }
    }
  }
}
</script>

<style scoped>
::v-deep .modal-content {
  width: 80vw;
}
::v-deep .form-group {
  margin-bottom: 0rem;
}

::v-deep .vue__time-picker {
  width: 7em;
}

::v-deep .vue__time-picker input.display-time {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  color: #5c6873;
  width: 7em;
}

::v-deep .btn {
  width: 10em;
}
</style>
